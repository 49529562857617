import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProductTable } from '../../container/ecommerce/Style';
// import EditCategory from '../../container/ecommerce/EditCategory';

const Product = lazy(() => import('../../container/ecommerce/product/Products'));
const ProductsTable = lazy(() => import('../../container/ecommerce/ProductsTable'));
const ProductAdd = lazy(() => import('../../container/ecommerce/product/AddProduct'));
const ProductEdit = lazy(() => import('../../container/ecommerce/product/EditProduct'));
const ProductDetails = lazy(() => import('../../container/ecommerce/product/ProductDetails'));
const Invoice = lazy(() => import('../../container/ecommerce/Invoice'));
const Orders = lazy(() => import('../../container/ecommerce/Orders'));
const Brands = lazy(() => import('../../container/ecommerce/Brands'));
const AddBrands = lazy(() => import('../../container/ecommerce/AddBrands'));
const EditBrand = lazy(() => import('../../container/ecommerce/EditBrand'));
const Categories = lazy(() => import('../../container/ecommerce/Categories'));
const AddCategory = lazy(() => import('../../container/ecommerce/AddCategory'));
const EditCategory = lazy(() => import('../../container/ecommerce/EditCategory'));

const Sellers = lazy(() => import('../../container/ecommerce/Sellers'));
const Cart = lazy(() => import('../../container/ecommerce/Cart'));
const Checkout = lazy(() => import('../../container/ecommerce/Checkout'));
const NotFound = lazy(() => import('../../container/pages/404'));

function EcommerceRoute() {
  return (
    <Routes>
      <Route path="products/*" element={<Product />} />
      <Route exact path="productsTable" element={<ProductsTable />} />
      <Route exact path="productsTable/add-product" element={<ProductAdd />} />
      <Route exact path="edit-product" element={<ProductEdit />} />
      <Route exact path="productDetails/:id" element={<ProductDetails />} />
      <Route exact path="invoice" element={<Invoice />} />
      <Route exact path="orders" element={<Orders />} />
      <Route exact path="brands" element={<Brands />} />
      <Route exact path="brands/add_brand" element={<AddBrands />} />
      <Route exact path="brands/:id" element={<EditBrand />} />
      <Route exact path="categories" element={<Categories />} />
      <Route exact path="categories/add_category" element={<AddCategory />} />
      <Route exact path="categories/editCategory/:id" element={<EditCategory />} />
     
      <Route exact path="sellers" element={<Sellers />} />
      <Route path="cart/*" element={<Cart />} />
      <Route path="checkout/*" element={<Checkout />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default EcommerceRoute;
