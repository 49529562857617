import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Marketers = lazy(() => import('../../container/marketing/Marketers'));
const NotFound = lazy(() => import('../../container/pages/404'));

function MarketingRoutes() {
  return (
    <Routes>
      <Route path="marketers" element={<Marketers />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MarketingRoutes;
