import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const FbLogin = lazy(() => import('../container/profile/authentication/overview/FbSignIn'));
const FbSignUp = lazy(() => import('../container/profile/authentication/overview/FbSignup'));
const HomePage = lazy(() => import('../container/pages/home-page/homePage'));
const About = lazy(() => import('../container/pages/home-page/about'));
const HomePricing = lazy(() => import('../container/pages/home-page/pricingPage'));
const FaQ = lazy(() => import('../container/pages/home-page/Faq'));
// const NotFound = lazy(() => import('../container/pages/404'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => navigate('/'));
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      {/* <Route index element={<HomePage />} />
      <Route path='about' element={<About />} />
      <Route path='homepricing' element={<HomePricing />} />
      <Route path='faq' element={<FaQ />} /> */}
      <Route index element={<Login />} />
      <Route path="forgotPassword" element={<ForgotPass />} />
      <Route path="register" element={<SignUp />} />
      {/* <Route path="fbRegister" element={<FbSignUp />} /> */}
      {/* <Route path="fbSignIn" element={<FbLogin />} /> */}
      {/* <Route path="*" element={<NotFound />} /> */}
      <Route path="*" element={<AuthRoot />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
